import * as React from 'react';
import {
  Container,
  Typography,
  Avatar,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Tabs,
  Tab,
} from '@mui/material';
import { styled } from '@mui/system';
import Header from '../../components/common/Header/Header';
import PropTypes from 'prop-types';
import Personaldetails from '../../components/common/Personaldetails/Personaldetails';
import './Profile.css';

const DashboardContainer = styled(Container)(({ theme }) => ({
  margin: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    margin: 0,
    padding: 0,
    width: '100%',
  },
}));

const ProfilePicContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(2),
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const Profile = () => {
  const [value, setValue] = React.useState(0);
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleDrawerItemClick = (index) => {
    setValue(index);
    setDrawerOpen(false);
  };

  const drawer = (
    <div>
      <List>
        <ListItem button onClick={() => handleDrawerItemClick(0)}>
          <ListItemText primary="Personal Details" />
        </ListItem>
        <ListItem button onClick={() => handleDrawerItemClick(1)}>
          <ListItemText primary="Reports" />
        </ListItem>
        <ListItem button onClick={() => handleDrawerItemClick(2)}>
          <ListItemText primary="Change PIN" />
        </ListItem>
        <ListItem button onClick={() => handleDrawerItemClick(3)}>
          <ListItemText primary="Active devices" />
        </ListItem>
        <ListItem button onClick={() => handleDrawerItemClick(4)}>
          <ListItemText primary="Know your Prakruti" />
        </ListItem>
        <ListItem button onClick={() => handleDrawerItemClick(5)}>
          <ListItemText primary="Medical Profile" />
        </ListItem>
        <ListItem button onClick={() => handleDrawerItemClick(12)}>
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
    </div>
  );

  return (
    <>
      <Header
        pageName="Profile"
        onProfileButtonClick={handleDrawerToggle}
        isLoggedIn={true} // Set this dynamically based on user login status
      />
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        PaperProps={{
          style: { marginTop: '64px' },
        }}
      >
        {drawer}
      </Drawer>

      <DashboardContainer maxWidth="md" className="profile-container">
        <ProfilePicContainer className="profilePic">
          <Avatar
            alt="Profile Picture"
            src=""
            sx={{ width: 100, height: 100 }}
          />
          <Typography variant="h5" mt={2} mb={1}>
            Name
          </Typography>
        </ProfilePicContainer>

        <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 'auto' }}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs"
            sx={{
              borderRight: 1,
              borderColor: 'divider',
              textAlign: 'left',
              display: { xs: 'none', sm: 'block' },
            }}
          >
            <Tab label="Personal Details" {...a11yProps(0)} />
            <Tab label="Reports" {...a11yProps(1)} />
            <Tab label="Change PIN" {...a11yProps(2)} />
            <Tab label="Active devices" {...a11yProps(3)} />
            <Tab label="Know your Prakruti" {...a11yProps(4)} />
            <Tab label="Medical Profile" {...a11yProps(5)} />
          </Tabs>

          <TabPanel value={value} index={0}>
            <Personaldetails />
          </TabPanel>
          <TabPanel value={value} index={1}>
            Reports
          </TabPanel>
          <TabPanel value={value} index={2}>
            Change PIN
          </TabPanel>
          <TabPanel value={value} index={3}>
            Active devices
          </TabPanel>
          <TabPanel value={value} index={4}>
            <Typography>Know your Prakruti</Typography>
          </TabPanel>
          <TabPanel value={value} index={5}>
            Medical Profile
          </TabPanel>
        </Box>
      </DashboardContainer>
    </>
  );
};

export default Profile;
