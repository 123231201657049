import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  TextField,
  Button,
  Typography,
  Grid,
  FormControl,
  Autocomplete,
  CircularProgress,
} from '@mui/material';
import countries from '../../assets/json/countries.json';
import './Login.css';

const Forgotpin = () => {
  const navigate = useNavigate();
  const [country, setCountry] = useState('');
  const [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState(false);
  const [countryError, setCountryError] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleCountryChange = (event, value) => {
    setCountry(value ? value.code : '');
    setCountryError(false);
  };

  const handlePhoneChange = (event) => {
    const value = event.target.value.replace(/\D/g, '').slice(0, 10); // Allow only 10 digits
    setPhone(value);
    setPhoneError(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const isPhoneValid = phone.length === 10;
    const isCountryValid = country !== '';
    setPhoneError(!isPhoneValid);
    setCountryError(!isCountryValid);
    if (isPhoneValid && isCountryValid) {
      navigate('/pin'); // Redirect to pin verification page
    }
  };

  const countryOptions = countries.map((country) => ({
    value: country.dial_code,
    label: `${country.name} (${country.dial_code})`,
    icon: `https://flagcdn.com/16x12/${country.code.toLowerCase()}.png`,
  }));
  

  return (
    <Container maxWidth="sm" className="login-container">
      <Box className="login-box">
        <Typography variant="h4" align="center" gutterBottom>
          Forgot PIN
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <Typography variant="h6" align="left" gutterBottom>
                Your Phone Number
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                options={countryOptions}
                onChange={handleCountryChange}
                getOptionLabel={(option) => option.label}
                renderOption={(props, option) => (
                  <li {...props}>
                    <img
                      src={option.icon}
                      alt={option.label}
                      style={{ marginRight: 10 }}
                    />
                    {option.label}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Country"
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password',
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                variant="outlined"
                label="Phone Number"
                value={phone}
                onChange={handlePhoneChange}
                error={phoneError}
                helperText={phoneError ? 'Enter a valid phone number' : ''}
                inputProps={{ maxLength: 10 }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
              >
                Send OTP & Reset PIN
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Container>
  );
};

export default Forgotpin;
