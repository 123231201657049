import React from 'react';
import { AppBar, Toolbar, Typography, Box, IconButton } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const Header = ({ pageName, onProfileButtonClick, isLoggedIn }) => {
  return (
    <AppBar position="static">
      <Toolbar>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h6">Taxnano</Typography>
        </Box>
        <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
          <Typography variant="h6">{pageName}</Typography>
        </Box>
          <Box sx={{ flexGrow: 1, textAlign: 'right' }}>
            <Link to='/profile'>
              <IconButton
                edge="end"
                color="inherit"
                aria-label="profile"
                onClick={onProfileButtonClick}
              >
                <AccountCircle />
              </IconButton>
            </Link>
          </Box>
      </Toolbar>
    </AppBar>
  );
};

Header.propTypes = {
  pageName: PropTypes.string.isRequired,
  onProfileButtonClick: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
};

export default Header;
