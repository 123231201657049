import React from 'react';
import { Box, Typography, TextField, Grid, Button } from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';

const Personaldetails = () => {
    const [gender, setGender] = React.useState(0);
    const handleGenderChange = (event) => {
        setGender(event.target.value);
    };

    return (
        <>
            <b>Personal Details</b>
            <form>
                <Grid container spacing={2} mt={3}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                        fullWidth
                        label="Name"
                        name="name"
                        value='Username'
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                        fullWidth
                        label="Date of Birth"
                        name="dob"
                        type="date"
                        value='22-04-2002'
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                        fullWidth
                        label="Phone"
                        name="phone"
                        value='1234567890'
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                        fullWidth
                        label="Email"
                        name="email"
                        type="email"
                        value='test@gmail.com'
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Select
                        select
                        fullWidth
                        label="Gender"
                        name="gender"
                        value={gender}
                        onChange={handleGenderChange}
                        >
                        <MenuItem  value="male">Male</MenuItem>
                        <MenuItem  value="female">Female</MenuItem>
                        </Select>
                    </Grid>
                    {/* <Grid item xs={12} sm={6}>
                        <Autocomplete
                        fullWidth
                        options={['USD', 'INR', 'EUR', 'GBP', 'JPY']}
                        value=''
                        />
                    </Grid> */}
                </Grid>
                <Box mt={3} textAlign="center">
                <Button variant="contained" color="primary" type="submit">
                    Save
                </Button>
                </Box>
            </form>
        </>
    );
};

export default Personaldetails;
