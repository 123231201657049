import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  TextField,
  Button,
  Typography,
  Grid,
  Popover,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import countries from '../../assets/json/countries.json'; // Adjusted path to countries.json
import './Login.css';
import { Close as CloseIcon } from '@mui/icons-material'; // Import CloseIcon from MUI

const countryOptions = countries.map((country) => ({
  value: country.dial_code,
  label: `${country.name} (${country.dial_code})`,
  icon: `https://flagcdn.com/16x12/${country.code.toLowerCase()}.png`,
}));

const savedPhoneNumbers = ['+1234567890', '+1987654321', '+1654321987']; // Example of previously saved phone numbers

const Login = () => {
  const navigate = useNavigate();
  const [country, setCountry] = useState(null);
  const [phone, setPhone] = useState('');
  const [pin, setPin] = useState(['', '', '', '']);
  const [anchorEl, setAnchorEl] = useState(null);
  const [phoneNumberError, setPhoneNumberError] = useState(false); // State for phone number error

  const handleCountryChange = (event, selectedOption) => {
    setCountry(selectedOption);
  };

  const handlePhoneChange = (event) => {
    const formattedPhoneNumber = formatPhoneNumber(event.target.value);
    setPhone(formattedPhoneNumber);
  };

  const handleSwitchAccountClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePhoneSelect = (phoneNumber) => {
    setPhone(phoneNumber);
    setAnchorEl(null); // Close popover
  };

  const handleRemovePhone = (phoneNumberToRemove) => {
    const updatedPhoneNumbers = savedPhoneNumbers.filter(
      (phoneNumber) => phoneNumber !== phoneNumberToRemove
    );
    // Update saved phone numbers list
  };

  const handlePinChange = (index, value) => {
    if (/^\d$/.test(value) || value === '') {
      const newPin = [...pin];
      newPin[index] = value;
      setPin(newPin);
      if (value && index < 3) {
        document.getElementById(`pin-${index + 1}`).focus();
      }
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!phone || !isPhoneNumberValid(phone)) {
      setPhoneNumberError(true);
      return;
    } else {
      setPhoneNumberError(false);
    }
    if (pin.join('') === '1234') {
      navigate('/dashboard');
    } else {
      alert('Incorrect PIN');
    }
  };

  const formatPhoneNumber = (phoneNumber) => {
    // Basic phone number formatting (example: +1234567890)
    return phoneNumber.replace(/[^0-9+]/g, '').slice(0, 13); // Limit to 13 characters including '+'
  };

  const isPhoneNumberValid = (phoneNumber) => {
    // Basic validation: must start with '+' and have at least 10 digits
    return /^\+?[0-9]{10,}$/.test(phoneNumber);
  };

  return (
    <Container maxWidth="sm" className="login-container">
      <Box className="login-box">
        <Typography variant="h4" align="center" gutterBottom>
          Login
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={6}>
              <Autocomplete
                options={countryOptions}
                onChange={handleCountryChange}
                getOptionLabel={(option) => option.label}
                renderOption={(props, option) => (
                  <li {...props}>
                    <img
                      src={option.icon}
                      alt={option.label}
                      style={{ marginRight: 10 }}
                    />
                    {option.label}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Country"
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password',
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Phone Number"
                value={phone}
                onChange={handlePhoneChange}
                type="tel"
                error={phoneNumberError}
                helperText={phoneNumberError && 'Enter a valid phone number'}
              />
            </Grid>
            <Grid item xs={12} alignItems="right" className="switch-account-container">
              <Button
                onClick={handleSwitchAccountClick}
                className="switch-account-button"
                sx={{ ml: 'auto' }} // Align to the right
              >
                Switch Account
              </Button>
              <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <List>
                  {savedPhoneNumbers.map((phoneNumber, index) => (
                    <ListItem
                      key={index}
                      button
                      onClick={() => handlePhoneSelect(phoneNumber)}
                    >
                      <ListItemText primary={phoneNumber} />
                      <Button onClick={() => handleRemovePhone(phoneNumber)}>
                        <CloseIcon fontSize="small" />
                      </Button>
                    </ListItem>
                  ))}
                </List>
              </Popover>
            </Grid>
          </Grid>
          <Box mt={2}>
            <Typography variant="h6" align="left">
              Enter Your PIN
            </Typography>
            <Grid container spacing={1} justifyContent="center">
              {pin.map((digit, index) => (
                <Grid item xs={3} key={index}>
                  <TextField
                    id={`pin-${index}`}
                    value={digit}
                    onChange={(e) => handlePinChange(index, e.target.value)}
                    inputProps={{
                      maxLength: 1,
                      style: { textAlign: 'center' },
                    }}
                  />
                </Grid>
              ))}
            </Grid>
            <Typography variant="body2" align="right">
                <Button onClick={() => navigate('/forgotpin')}>Forgot PIN</Button>
            </Typography>
          </Box>
          <Box mt={2}>
            <Button variant="contained" color="primary" type="submit" fullWidth>
              Login
            </Button>
          </Box>
          <Box mt={2}>
            <Typography variant="body2" align="center">
              Don't have an account? <Button onClick={() => navigate('/signup')}>Sign up</Button>
            </Typography>
          </Box>
        </form>
      </Box>
    </Container>
  );
};

export default Login;
