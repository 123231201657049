// store.js
import { configureStore } from '@reduxjs/toolkit';
import userReducer from './utils/userSlice'; // Adjust the path as per your project structure

const store = configureStore({
  reducer: {
    user: userReducer,
    // Add other reducers here
  },
});

export default store;
