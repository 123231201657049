import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Login.css';
import Input from '../../components/forms/Input';
import Button from '../../components/common/Button';

const OTPVerification = () => {
    const [otp, setOtp] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const location = useLocation();

    const handleSubmit = (e) => {
        e.preventDefault();
        const temporaryOtp = '1234';

        if (otp === temporaryOtp) {
            navigate('/dashboard');
        } else {
            setError('OTP verification failed. Please try again.');
        }
    };

    return (
        <div className="otp-verification">
            <form onSubmit={handleSubmit}>
                <h2>OTP Verification</h2>
                <Input
                    type="text"
                    placeholder="Enter OTP"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                />
                <Button type="submit">Verify OTP</Button>
                {error && <p className="error">{error}</p>}
            </form>
        </div>
    );
};

export default OTPVerification;
