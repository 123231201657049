import React, { useState } from 'react';
import Header from '../../components/common/Header/Header';
import {
  Container,
  Typography,
  Grid,
  TextField
} from '@mui/material';
import { styled } from '@mui/system';
import Entrybox from '../../components/forms/Entrybox';
import './Dashboard.css';

const DashboardContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(4),
}));

const Dashboard = () => {
  
  return (
    <>
      <Header />
      <DashboardContainer>
        <Grid>
          <TextField
              label="Grand Total"
              variant="outlined"
              fullWidth
              value='Grand Total(Investments), ($)Total Amount, ($)Average Percentage'
              className="text-input"
            />
        </Grid>
        <Entrybox/>

      </DashboardContainer>
    </>
  );
};

export default Dashboard;
