import { createSlice } from '@reduxjs/toolkit';


const initialState = {
  user: {
    name: '',
    dob: '',
    phone: '',
    email: '',
    gender: '',
    currency: '',
    profilePicture: null,
  },
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserProfile: (state, action) => {
      state.user = { ...state.user, ...action.payload };
    },
    updateUserProfile: (state, action) => {
      state.user = { ...state.user, ...action.payload };
    },
    setProfilePicture: (state, action) => {
      state.user.profilePicture = action.payload;
    },
  },
});

export const { setUserProfile, updateUserProfile, setProfilePicture } = userSlice.actions;

// Selector function to get user profile from state
export const selectUser = (state) => state.user.user;

export default userSlice.reducer;
