import React from 'react';
import PropTypes from 'prop-types';
import './Button.css';

const Button = ({ type, onClick, children }) => (
    <button type={type} onClick={onClick}>
        {children}
    </button>
);

Button.propTypes = {
    type: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    children: PropTypes.node.isRequired,
};

export default Button;
