import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import Home from './pages/Home';
import Login from './modules/Login/Login';
import Signup from './modules/Login/Signup';
import Forgotpin from './modules/Login/Forgotpin';
import OTPVerification from './modules/Login/OTPVerification';
import Pinverify from './modules/Login/Createpin';
import Dashboard from './modules/Dashboard/Dashboard';
import Profile from './modules/Profile/Profile';
import './assets/styles/global.css';

const App = () => {
    const base_url = process.env.REACT_APP_BASE_URL;
    console.log(base_url);
    return (
        <AuthProvider>
            <Router basename={base_url}>
                <Routes>
                    <Route path="/" element={<Home/>} />
                    <Route path="/login" element={<Login/>} />
                    <Route path="/signup" element={<Signup/>} />
                    <Route path="/forgotpin" element={<Forgotpin/>} />
                    <Route path="/otp-verification" element={<OTPVerification/>} />
                    <Route path="/pin" element={<Pinverify/>} />
                    <Route path="/dashboard" element={<Dashboard/>} />
                    <Route path="/profile" element={<Profile/>} />
                </Routes>
            </Router>
        </AuthProvider>
    );
};

export default App;
