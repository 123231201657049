import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  TextField,
  Button,
  Typography,
  Grid,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import Select from 'react-select';
import countries from '../../assets/json/countries.json'; // Adjusted path to countries.json
import './Login.css';

const countryOptions = countries.map((country) => ({
  value: country.dial_code,
  label: (
    <div className="country-option">
      <img
        src={`https://flagcdn.com/16x12/${country.code.toLowerCase()}.png`}
        alt={country.name}
        className="country-flag"
      />
      {country.name} ({country.dial_code})
    </div>
  ),
}));

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    minHeight: 56,
    border: state.isFocused ? '1px solid #1976D2' : '1px solid #ccc',
    boxShadow: state.isFocused ? '0 0 0 1px #1976D2' : 'none',
    '&:hover': {
      border: state.isFocused ? '1px solid #1976D2' : '1px solid #ccc',
    },
  }),
};

const Signup = () => {
  const navigate = useNavigate();
  const [country, setCountry] = useState(null);
  const [phone, setPhone] = useState('');
  const [termsChecked, setTermsChecked] = useState(false); // State for terms checkbox

  const handleCountryChange = (selectedOption) => {
    setCountry(selectedOption);
  };

  const handlePhoneChange = (event) => {
    const formattedPhoneNumber = formatPhoneNumber(event.target.value);
    setPhone(formattedPhoneNumber);
  };

  const handleCheckboxChange = (event) => {
    setTermsChecked(event.target.checked);
  };

  const handleSendOTP = () => {
    // Logic to send OTP, navigate to OTP verification page
    navigate('/pin');
  };

  const formatPhoneNumber = (phoneNumber) => {
    // Basic phone number formatting (example: +1234567890)
    return phoneNumber.replace(/[^0-9+]/g, '').slice(0, 13); // Limit to 13 characters including '+'
  };

  const isPhoneNumberValid = (phoneNumber) => {
    // Basic validation: must start with '+' and have at least 10 digits
    return /^\+?[0-9]{10,}$/.test(phoneNumber);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (termsChecked && isPhoneNumberValid(phone)) {
      handleSendOTP();
    }
  };

  return (
    <Container maxWidth="sm" className="login-container">
      <Box className="login-box">
        <Typography variant="h4" align="center" gutterBottom>
          Your Phone Number
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={6}>
              <Select
                options={countryOptions}
                onChange={handleCountryChange}
                placeholder="Select Country"
                styles={customStyles}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Phone Number"
                value={phone}
                onChange={handlePhoneChange}
                type="tel"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={termsChecked}
                    onChange={handleCheckboxChange}
                    color="primary"
                  />
                }
                label={
                  <Typography variant="body2">
                    Read and agree to the{' '}
                    <Button
                      component="a"
                      href="/termsandcondition"
                      target="_blank"
                      rel="noopener noreferrer"
                      color="primary"
                      style={{ textDecoration: 'underline' }}
                    >
                      Terms & conditions
                    </Button>
                  </Typography>
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
                disabled={!termsChecked || !isPhoneNumberValid(phone)}
              >
                Send OTP
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" align="center">
                Already have an account?{' '}
                <Button onClick={() => navigate('/login')}>Login</Button>
              </Typography>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Container>
  );
};

export default Signup;
