import React, { useState } from 'react';
import {
  Box,
  Container,
  TextField,
  Button,
  Typography,
  Grid,
  IconButton,
  Collapse,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  Badge,
  Alert,
} from '@mui/material';
import { styled } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';


const DashboardContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(4),
}));

const Entrybox = () => {
  
  const [fields, setFields] = useState([
    { text: '', number1: '', percentage: '', subFields: [], expanded: false, showDelete: false, totalAmount: 0, totalPercentage: 0, averagePercentage: 0 }
  ]);
  
  const handleAddClick = () => {
    setFields([
      ...fields,
      { text: '', number1: '', percentage: '', subFields: [], expanded: false, showDelete: false, totalAmount: 0, totalPercentage: 0, averagePercentage: 0 }
    ]);
  };

  const [open, setOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [modalData, setModalData] = useState({ text: '', number1: '', percentage: '', startDate: '', endDate: '' });
  const [alertOpen, setAlertOpen] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [deleteType, setDeleteType] = useState(null);
  const [warning, setWarning] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [highlightedSubIndex, setHighlightedSubIndex] = useState(null);
  
  const handleFieldChange = (index, field) => (event) => {
    const newFields = [...fields];
    newFields[index][field] = event.target.value;
    newFields[index].showDelete = true;
    setFields(newFields);
  };
  
  const handleDeleteClick = (index, type) => () => {
    setDeleteIndex(index);
    setDeleteType(type);
    setAlertOpen(true);
  };
  
  const handleDeleteConfirm = () => {
    if (deleteType === 'parent') {
      const newFields = fields.filter((_, i) => i !== deleteIndex);
      setFields(newFields);
    } else if (deleteType === 'sub') {
      const [parentIndex, subIndex] = deleteIndex;
      const newFields = [...fields];
      newFields[parentIndex].subFields = newFields[parentIndex].subFields.filter((_, i) => i !== subIndex);
      newFields[parentIndex].totalAmount = calculateTotalAmount(newFields[parentIndex].subFields);
      newFields[parentIndex].totalPercentage = calculateTotalPercentage(newFields[parentIndex].subFields);
      newFields[parentIndex].averagePercentage = calculateAveragePercentage(newFields[parentIndex].subFields);
      newFields[parentIndex].startDate = calculateStartDate(newFields[parentIndex].subFields);
      newFields[parentIndex].endDate = calculateEndDate(newFields[parentIndex].subFields);
      setFields(newFields);
    }
    setAlertOpen(false);
    setDeleteIndex(null);
    setDeleteType(null);
  };
  
  const handleDeleteCancel = () => {
    setAlertOpen(false);
    setDeleteIndex(null);
    setDeleteType(null);
  };
  
  const handleOpenModal = (index) => () => {
    if (fields[index].text.trim() === '') {
      setWarning('First Enter Investment name.');
    } else {
      setCurrentIndex(index);
      setModalTitle(fields[index].name);
      setOpen(true);
      setWarning('');
    }
  };
  
  const handleCloseModal = () => {
    setOpen(false);
    setModalData({ name: '', number1: '', percentage: '', startDate: '', endDate: '' });
  };
  
  const handleModalChange = (field) => (event) => {
    if (field === 'number1' || field === 'percentage') {
      const newAmount = parseFloat(event.target.value) || 0;
      const newPercentage = parseFloat(modalData.percentage) || 0;
      const interest = (newAmount * newPercentage) / 100;
  
      setModalData({
        ...modalData,
        [field]: event.target.value,
        interest: interest.toFixed(2), // Adjust to your formatting needs
      });
    } else {
      setModalData({
        ...modalData,
        [field]: event.target.value,
      });
    }
  };
  
  const handleSaveModal = () => {
    const newFields = [...fields];
    const currentField = newFields[currentIndex];
    if (modalData.subIndex !== undefined) {
      // Update existing subField
      const updatedSubField = {
        ...modalData,
        interest: calculateInterest(modalData.number1, modalData.percentage),
        disabled: true,
      };
      currentField.subFields[modalData.subIndex] = updatedSubField;
    } else {
      // Add new subField
      const newSubField = {
        ...modalData,
        interest: calculateInterest(modalData.number1, modalData.percentage),
        disabled: true,
      };
      currentField.subFields.unshift(newSubField);
    }
  
    currentField.expanded = true;
    currentField.totalAmount = calculateTotalAmount(currentField.subFields);
    currentField.totalPercentage = calculateTotalPercentage(currentField.subFields);
    currentField.averagePercentage = calculateAveragePercentage(currentField.subFields);
    currentField.startDate = calculateStartDate(currentField.subFields);
    currentField.endDate = calculateEndDate(currentField.subFields);
    setFields(newFields);
    handleCloseModal();
};

const calculateInterest = (amount, percentage) => {
  const newAmount = parseFloat(amount) || 0;
  const newPercentage = parseFloat(percentage) || 0;
  const interest = (newAmount * newPercentage) / 100;
  return interest.toFixed(2); // Ensure interest is formatted correctly
};

  
  const handleSubFieldChange = (index, subIndex, field) => (event) => {
    const newFields = [...fields];
    newFields[index].subFields[subIndex][field] = event.target.value;
    newFields[index].totalAmount = calculateTotalAmount(newFields[index].subFields);
    newFields[index].totalPercentage = calculateTotalPercentage(newFields[index].subFields);
    newFields[index].averagePercentage = calculateAveragePercentage(newFields[index].subFields);
    newFields[index].startDate = calculateStartDate(newFields[index].subFields);
    newFields[index].endDate = calculateEndDate(newFields[index].subFields);
    setFields(newFields);
  };
  
  const calculateTotalAmount = (subFields) => {
    return subFields.reduce((total, subField) => total + parseFloat(subField.number1 || 0), 0);
  };
  
  const calculateTotalPercentage = (subFields) => {
    return subFields.reduce((total, subField) => total + parseFloat(subField.percentage || 0), 0);
  };
  
  const calculateAveragePercentage = (subFields) => {
    const totalPercentage = calculateTotalPercentage(subFields);
    return subFields.length > 0 ? (totalPercentage / subFields.length).toFixed(2) : 0;
  };
  
  const toggleCollapse = (index) => () => {
    const newFields = [...fields];
    newFields[index].expanded = !newFields[index].expanded;
    setFields(newFields);
  };

  // Calculate date in DD-MM-YYYY format
  const calculateStartDate = (subFields) => {
    if (subFields.length === 0) return '';
    const dates = subFields.map(subField => new Date(subField.startDate));
    return formatDate(new Date(Math.min(...dates)));
  };

  const calculateEndDate = (subFields) => {
    if (subFields.length === 0) return '';
    const dates = subFields.map(subField => new Date(subField.endDate));
    return formatDate(new Date(Math.max(...dates)));
  };

  const formatDate = (date) => {
    if (!date) return '';
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const handleParentFocus = (index) => {
    const newFields = [...fields];
    newFields[index].showDelete = true;
    setFields(newFields);
  };
  
  const handleParentBlur = (index) => {
    setTimeout(() => {
      const newFields = [...fields];
      newFields[index].showDelete = false;
      setFields(newFields);
    }, 100); // Add a small delay before hiding
  };

  
  const handleSubFocus = (parentIndex, subIndex) => {
    const newFields = [...fields];
    newFields[parentIndex].subFields[subIndex].showDelete = true;
    setFields(newFields);
  };
  
  const handleSubBlur = (parentIndex, subIndex) => {
    setTimeout(() => {
      const newFields = [...fields];
      newFields[parentIndex].subFields[subIndex].showDelete = false;
      setFields(newFields);
    }, 100); // Add a small delay before hiding
  };
  
  let clickTimeout = null;
  
  const handleSingleClick = (parentIndex, subIndex) => () => {
    setHighlightedSubIndex({ parentIndex, subIndex });
  };

  const handleDoubleClick = (parentIndex, subIndex) => () => {
      const subField = fields[parentIndex].subFields[subIndex];
      setModalData({ ...subField, parentIndex, subIndex });
      setCurrentIndex(parentIndex);
      setModalTitle(fields[parentIndex].name);
      setOpen(true);
  };


  return (
    <DashboardContainer>
      <Button
          variant="contained"
          color="primary"
          onClick={handleAddClick}
          className="add-button"
        >
          <AddIcon />
      </Button>
      {warning && <Alert severity="warning" onClose={() => {}}>{warning}</Alert>}
      <Grid container spacing={2} direction="column">
          {fields.map((field, index) => (
            <Grid container item direction="column" key={index}>
              <Paper elevation={3} className="field-container">
                <Grid container alignItems="center">
                  <Grid item>
                    <IconButton onClick={toggleCollapse(index)}>
                      {field.expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton
                      color="primary"
                      onClick={handleOpenModal(index)}
                    >
                      <AddIcon />
                    </IconButton>
                  </Grid>
                  <Grid className='wrapInput' item xs display={'flex'} flexDirection={'column'}>
                  <Badge badgeContent={field.subFields.length} color="primary">
                    <TextField
                      label="Name"
                      variant="outlined"
                      fullWidth
                      value={field.text}
                      onChange={handleFieldChange(index, 'text')}
                      onFocus={() => handleParentFocus(index)}
                      onBlur={() => handleParentBlur(index)}
                      className="text-input"
                    />
                    </Badge>
                    {field.startDate && field.endDate && (
                      <Typography className='startEndDate' variant="caption" color="textSecondary">
                        {`(${field.startDate} - ${field.endDate})`}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs>
                    <TextField
                      label="Total Amount"
                      variant="outlined"
                      fullWidth
                      type="number"
                      value={field.totalAmount}
                      onChange={handleFieldChange(index, 'number1')}
                      disabled
                      className="number-input"
                    />
                  </Grid>
                  <Grid item xs>
                    <TextField
                      label="Total Percentage"
                      variant="outlined"
                      fullWidth
                      type="text"
                      value={`${field.totalPercentage} (${field.averagePercentage}%)`}
                      onChange={handleFieldChange(index, 'percentage')}
                      disabled
                      className="percentage-input"
                    />
                  </Grid>
                  <Grid item>
                    <IconButton
                      color="secondary"
                      onClick={handleDeleteClick(index, 'parent')}
                      style={{ display: field.showDelete ? 'block' : 'none' }}
                    >
                      <RemoveCircleIcon style={{ color: 'red' }} />
                    </IconButton>
                  </Grid>
                  
                </Grid>
                <Collapse className='subInputs' in={field.expanded}>
                  <Grid container item spacing={2} direction="column" className="subfield-container">
                    {field.subFields.map((subField, subIndex) => (
                      <Grid container item alignItems="center" key={subIndex}>
                        <Grid item xs={1} />
                        <Grid className='wrapInput'  item xs>
                          <TextField
                            label="Text"
                            variant="outlined"
                            fullWidth
                            value={subField.text}
                            onChange={handleSubFieldChange(index, subIndex, 'text')}
                            onFocus={() => handleSubFocus(index, subIndex)}
                            onBlur={() => handleSubBlur(index, subIndex)}
                            onClick={handleSingleClick(index, subIndex)}
                            onDoubleClick={handleDoubleClick(index, subIndex)}
                            className={highlightedSubIndex && highlightedSubIndex.parentIndex === index && highlightedSubIndex.subIndex === subIndex ? 'highlighted' : 'sub-text-input'}
                            InputProps={{
                                style: {
                                    borderColor: highlightedSubIndex && highlightedSubIndex.parentIndex === index && highlightedSubIndex.subIndex === subIndex ? 'red' : undefined
                                },
                                readOnly: subField.disabled,
                            }}
                          />
                            <Typography className='startEndDate' variant="caption" color="textSecondary">
                              {`(${subField.startDate} - ${subField.endDate})`}
                            </Typography>
                        </Grid>
                        <Grid item xs>
                          <TextField
                            label="Amount"
                            variant="outlined"
                            fullWidth
                            type="number"
                            value={subField.number1}
                            onChange={handleSubFieldChange(index, subIndex, 'number1')}
                            onFocus={() => handleSubFocus(index, subIndex)}
                            onBlur={() => handleSubBlur(index, subIndex)}
                            onClick={handleSingleClick(index, subIndex)}
                            onDoubleClick={handleDoubleClick(index, subIndex)}
                            className={highlightedSubIndex && highlightedSubIndex.parentIndex === index && highlightedSubIndex.subIndex === subIndex ? 'highlighted' : 'sub-text-input'}
                            InputProps={{
                                style: {
                                    borderColor: highlightedSubIndex && highlightedSubIndex.parentIndex === index && highlightedSubIndex.subIndex === subIndex ? 'red' : undefined
                                },
                                readOnly: subField.disabled,
                            }}
                          />
                        </Grid>
                        <Grid item xs>
                          <TextField
                            label="Interest (In Percentage)"
                            variant="outlined"
                            fullWidth
                            type="number"
                            value={subField.percentage}
                            onChange={handleSubFieldChange(index, subIndex, 'percentage')}
                            onFocus={() => handleSubFocus(index, subIndex)}
                            onBlur={() => handleSubBlur(index, subIndex)}
                            onClick={handleSingleClick(index, subIndex)}
                            onDoubleClick={handleDoubleClick(index, subIndex)}
                            className={highlightedSubIndex && highlightedSubIndex.parentIndex === index && highlightedSubIndex.subIndex === subIndex ? 'highlighted' : 'sub-text-input'}
                            InputProps={{
                                style: {
                                    borderColor: highlightedSubIndex && highlightedSubIndex.parentIndex === index && highlightedSubIndex.subIndex === subIndex ? 'red' : undefined
                                },
                                readOnly: subField.disabled,
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <IconButton
                            color="secondary"
                            onClick={handleDeleteClick([index, subIndex], 'sub')}
                            style={{ display: field.showDelete ? 'block' : 'none' }}
                          >
                            <RemoveCircleIcon style={{ color: 'red' }} />
                          </IconButton>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Collapse>
              </Paper>
            </Grid>
          ))}
        </Grid>

        <Dialog open={open} onClose={handleCloseModal}>
          <DialogTitle>{modalTitle}</DialogTitle>
          <DialogContent>
            <Box display="flex" flexWrap="nowrap" gap={2}>
              <TextField
                autoFocus
                margin="dense"
                label="Text"
                type="text"
                value={modalData.text}
                onChange={handleModalChange('text')}
              />
              <TextField
                margin="dense"
                label="Amount"
                type="number"
                value={modalData.number1}
                onChange={handleModalChange('number1')}
              />
              <TextField
                margin="dense"
                label="Interest (In Percentage)"
                type="number"
                value={modalData.percentage}
                onChange={handleModalChange('percentage')}
              />
            </Box>
            <Box display="flex" flexWrap="nowrap" gap={2}>
              <TextField
                margin="dense"
                label="Start Date"
                type="date"
                value={modalData.startDate}
                onChange={handleModalChange('startDate')}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
              <TextField
                margin="dense"
                label="End Date"
                type="date"
                value={modalData.endDate}
                onChange={handleModalChange('endDate')}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSaveModal} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={alertOpen}
          onClose={handleDeleteCancel}
        >
          <DialogTitle>
            {deleteType === 'parent' ? 'Are you sure? You want to delete all your investment!' : 'Are you sure? You want to delete!'}
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleDeleteCancel} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDeleteConfirm} color="secondary">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
    </DashboardContainer>
  );
};

export default Entrybox;
