import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  TextField,
  Button,
  Typography,
  Grid,
} from '@mui/material';
import './Login.css';

const Pinverify = () => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState(['', '', '', '']);
  const [pin, setPin] = useState(['', '', '', '']);
  const [confirmPin, setConfirmPin] = useState(['', '', '', '']);
  const [pinError, setPinError] = useState(false);
  const [confirmPinError, setConfirmPinError] = useState(false);
  const [otpError, setOtpError] = useState(false);
  const [timer, setTimer] = useState(60);
  const [showResend, setShowResend] = useState(false);
  const [otpComplete, setOtpComplete] = useState(false);

  const otpInputRefs = useRef([]);
  const pinInputRefs = useRef([]);
  const confirmPinInputRefs = useRef([]);

  useEffect(() => {
    const countdown = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer <= 1) {
          clearInterval(countdown);
          setShowResend(true);
          return 0;
        }
        return prevTimer - 1;
      });
    }, 1000);

    return () => clearInterval(countdown);
  }, []);

  const handleOtpChange = (index) => (event) => {
    const newOtp = [...otp];
    const value = event.target.value.replace(/\D/g, '').slice(0, 1);
    newOtp[index] = value;
    setOtp(newOtp);

    if (value) {
      if (index < 3) {
        otpInputRefs.current[index + 1].focus();
      } else {
        setOtpComplete(true);
        // Simulate OTP validation logic
        if (newOtp.join('') !== '1234') { // Assuming '1234' is the correct OTP for example purposes
          setOtpError(true);
        } else {
          setOtpError(false);
        }
      }
    } else if (index > 0) {
      otpInputRefs.current[index - 1].focus();
    }
  };

  const handlePinChange = (index) => (event) => {
    const newPin = [...pin];
    const value = event.target.value.replace(/\D/g, '').slice(0, 1);
    newPin[index] = value;
    setPin(newPin);

    if (value) {
      if (index < 3) {
        pinInputRefs.current[index + 1].focus();
      }
    } else if (index > 0) {
      pinInputRefs.current[index - 1].focus();
    }
    
    // Real-time PIN validation
    const enteredPin = newPin.join('');
    const enteredConfirmPin = confirmPin.join('');
    if (enteredPin && enteredConfirmPin && enteredPin !== enteredConfirmPin) {
      setPinError(true);
      setConfirmPinError(true);
    } else {
      setPinError(false);
      setConfirmPinError(false);
    }
  };

  const handleConfirmPinChange = (index) => (event) => {
    const newConfirmPin = [...confirmPin];
    const value = event.target.value.replace(/\D/g, '').slice(0, 1);
    newConfirmPin[index] = value;
    setConfirmPin(newConfirmPin);

    if (value) {
      if (index < 3) {
        confirmPinInputRefs.current[index + 1].focus();
      }
    } else if (index > 0) {
      confirmPinInputRefs.current[index - 1].focus();
    }
    
    // Real-time PIN validation
    const enteredPin = pin.join('');
    const enteredConfirmPin = newConfirmPin.join('');
    if (enteredPin && enteredConfirmPin && enteredPin !== enteredConfirmPin) {
      setPinError(true);
      setConfirmPinError(true);
    } else {
      setPinError(false);
      setConfirmPinError(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const enteredPin = pin.join('');
    const enteredConfirmPin = confirmPin.join('');
    if (enteredPin !== enteredConfirmPin) {
      setPinError(true);
      setConfirmPinError(true);
      return;
    }
    navigate('/login');
  };

  const resendOtp = () => {
    setOtp(['', '', '', '']);
    setTimer(60);
    setShowResend(false);
    setOtpComplete(false);
  };

  return (
    <Container maxWidth="sm" className="login-container">
      <Box className="login-box">
        <Typography variant="h4" align="center" gutterBottom>
          Verify OTP & Create PIN
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} alignItems="center">
            {/* OTP Section */}
            <Grid item xs={12}>
              <Typography variant="h6" align="left" gutterBottom>
                Enter OTP
              </Typography>
              <Typography align="left" color="textSecondary" gutterBottom>
                Please enter the 4 digit code sent to your phone.
              </Typography>
              <Grid className='singleInput' container justifyContent="center">
                {otp.map((digit, index) => (
                  <Grid className='oneInput' item key={index}>
                    <TextField
                      inputRef={(el) => (otpInputRefs.current[index] = el)}
                      className="otp-input"
                      variant="outlined"
                      size="small"
                      type="tel"
                      value={digit}
                      onChange={handleOtpChange(index)}
                      inputProps={{
                        maxLength: 1,
                        style: { textAlign: 'center' },
                      }}
                      error={otpError}
                    />
                  </Grid>
                ))}
              </Grid>
              {otpError && (
                <Typography color="error" align="center">
                  Invalid OTP. Please try again.
                </Typography>
              )}
              <Grid container justifyContent="center">
                {showResend ? (
                  <Button onClick={resendOtp}>Resend OTP</Button>
                ) : (
                  <Typography variant="body2">
                    Resend OTP in {timer} seconds
                  </Typography>
                )}
              </Grid>
            </Grid>

            {/* Create PIN Section */}
            <Grid
              item
              xs={12}
              style={{
                opacity: otpComplete && !otpError ? 1 : 0.5,
                pointerEvents: otpComplete && !otpError ? 'auto' : 'none',
              }}
            >
              <Typography align="left" gutterBottom>
                Enter 4 digit PIN
              </Typography>
              <Grid className='singleInput' container justifyContent="center">
                {pin.map((digit, index) => (
                  <Grid className='oneInput' item key={index}>
                    <TextField
                      inputRef={(el) => (pinInputRefs.current[index] = el)}
                      className="pin-input"
                      variant="outlined"
                      size="small"
                      type="password"
                      value={digit}
                      onChange={handlePinChange(index)}
                      inputProps={{
                        maxLength: 1,
                        style: { textAlign: 'center' },
                      }}
                      error={pinError}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>

            {/* Confirm PIN Section */}
            <Grid
              item
              xs={12}
              style={{
                opacity: otpComplete && !otpError ? 1 : 0.5,
                pointerEvents: otpComplete && !otpError ? 'auto' : 'none',
              }}
            >
              <Typography align="left" gutterBottom>
                Reenter 4 digit PIN
              </Typography>
              <Grid className='singleInput' container justifyContent="center">
                {confirmPin.map((digit, index) => (
                  <Grid className='oneInput' item key={index}>
                    <TextField
                      inputRef={(el) => (confirmPinInputRefs.current[index] = el)}
                      className="pin-input"
                      variant="outlined"
                      size="small"
                      type="password"
                      value={digit}
                      onChange={handleConfirmPinChange(index)}
                      inputProps={{
                        maxLength: 1,
                        style: { textAlign: 'center' },
                      }}
                      error={confirmPinError}
                      helperText={confirmPinError ? 'PINs do not match' : ''}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>

            {/* Submit Button */}
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
                disabled={pin.join('') !== confirmPin.join('')}
              >
                Create PIN
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Container>
  );
};

export default Pinverify;
