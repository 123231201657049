import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './Home.css';

const Home = () => {
  const navigate = useNavigate();

  const handleLogin = () => {
    navigate('/login');
  };

  const handleSignup = () => {
    navigate('/signup');
  };

  return (
    <Box className="home-container">
      <Typography variant="h2">Welcome to Taxnano</Typography>
      <p>Simplifying tax management for everyone.</p>
      <Box className="home-buttons">
        <Button variant="contained" color="primary" onClick={handleLogin}>
          Login
        </Button>
        <Button variant="contained" color="secondary" onClick={handleSignup}>
          Sign Up
        </Button>
      </Box>
    </Box>
  );
};

export default Home;
